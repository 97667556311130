import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import TableOfContents from '../components/table-of-contents';
import Layout from "../components/layout";
import Posts from "../components/posts";
import Icon from "../components/icon.tsx";
import Translate from "../components/translate";
import resolveUrl from "../utils/urls/resolve-url";
import CommentsForm from "../components/comments-form";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const Debt = ({ 
  data: { mysqlTerms, allMysqlBlog, faq, subCategories }, 
  pageContext: { breadcrumbs, mysqlId },
  location: { pathname }
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={b.pbMd4}>
        <section className={`intro-products2 ${b.py4} ${b.pyMd5} `}>
          <div className={[b.container, "breadcrumbs-mobile-products", b.pb3, "scroll-inner"].join(" ")}>
            <Breadcrumbs textWhite items={breadcrumbs}/>
          </div>
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={[b.colLg9, b.textWhite].join(' ')}>
                <h1 className={`big-title ${b.pb3} ${b.ptLg4} ${b.textWhite}`}>{mysqlTerms.title}</h1>
                <div className={b.textWhite} dangerouslySetInnerHTML={{ __html: mysqlTerms.description }} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
        {mysqlTerms.description_additional && <div className={`${b.row} product-page blog-content ${b.pb4}`}>
          <section className={`${b.colXl8} blackToGray dark-theme-listing`}>
            <BlogContent content={mysqlTerms.description_additional} />
            {faq?.nodes.length > 0 && (
              <>
                <h2 id="section-title-15">
                  <Translate tKey="Often asked questions" />
                </h2>
                {faq.nodes.map(({ title, text }, i) => (
                  <Faq title={title} text={text} key={i}/>
                ))}
                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                    <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                    <Translate tKey="Ask your own question" />
                  </button>
                </div>
              </>
            )}
          </section>
          
          <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="term" faq={isFaq} />
          
          <div className={[b.ptLg4, b.colXl4, b.mtLg2].join(' ')}>
            <TableOfContents selector=".post-content h2, .post-content h3, .post-content h4" intermediary />
          </div>
        </div>}

        {subCategories.nodes.length > 0 && 
          <div className={[b.mt4,b.mb3,b.mtLg5].join(' ')}>
            <h2 className={["title", b.dBlock, b.mb4].join(' ')}><Translate tKey="Topics in category:" /> {mysqlTerms.term}</h2>
            
            <div className={['subcategory-row', b.row].join(' ')}>
              {subCategories.nodes.map(({path,term, term_normalized}, i) => (
                <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                  <Link to={resolveUrl(path, term_normalized)}>
                    <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                      <div className='category-bl'/>
                      <div className={b.p3}>{term}</div>
                    </div>
                  </Link>
                </div>
              ))}
              {mysqlId === 76 && [
                ['/konta/konto-oszczednosciowe/', 'Konto oszczędnościowe'], 
                ['/konta/lokata/', 'Lokaty bankowe']
              ].map(([url, name], i) => (
                <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                  <Link to={url}>
                    <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                      <div className='category-bl'/>
                      <div className={b.p3}>{name}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        }

        {allMysqlBlog.nodes.length > 0 && (<aside className={[b.pt3, b.ptLg4, b.pb4, b.pbLg5, b.mbLg5].join(" ")}>
          <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}>
            <Translate tKey="Content related to" /> {mysqlTerms.term}
          </h2>
          <Posts items={allMysqlBlog.nodes} countItem={9999} step={4}/>
        </aside>)}
      </div>
    </Layout>
  )
}

export default Debt

export const query = graphql `
query Payments($mysqlId: Int!, $withoutUrls: [String]) {
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description
    title
    term_normalized
    description_alt
    description_additional
    mysqlId
    path
    term
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, term_id: {eq: $mysqlId}, is_category: {eq: 0}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  faq: allMysqlFaq(
    filter: {type: {eq: "term"}, item: {eq: $mysqlId}, answer: {ne: "null"}}
    sort: {fields: priority, order: DESC}
  ) {
    nodes {
     title: question
     text: answer
    }
  }
  subCategories: allMysqlTerms(filter: {mysqlParent: {eq: $mysqlId}, template: {ne: "products"}, menu: {eq: null}}) {
    nodes {
      term
      term_normalized
      path
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`